'use strict';

angular.module('cpformplastApp.jobs')
    .controller('jobHistoryController', function($scope, $state, DataManager, Notification, $modal, Modal) {

  $scope.schedules = [];
  $scope.jsonSchedules = [];

  $scope.scheduleTableStructure = [{
    'title':'Job',
    'value':'job',
    'key':'job',
    'filter':'alpha'
  },{
    'title':'Moule',
    'value':'mold',
    'key':'mold',
    'filter':'alpha'
  },{
    'title':'Client',
    'value':'client',
    'key':'client',
    'filter':'alpha'
  },{
    'title':'QTÉ.',
    'value':'producedQuantity',
    'key':'producedQuantity',
    'filter':'none'
  },{
    'title':'Début',
    'value':'start',
    'key':'start',
    'filter':'datetime'
  },{
    'title':'Fin',
    'value':'end',
    'key':'end',
    'filter':'datetime'
  },{
    'title':'Vendeur',
    'value':'salesman',
    'key':'salesman',
    'filter':'alpha'
  }];

  $scope.init = function() {
    DataManager.fetchSubjobSchedules('history')
    .then(schedules => {
      $scope.schedules = schedules;
      $scope.jsonSchedules = schedules.map(schedule => schedule.getJson($scope.scheduleTableStructure))
    })
    .catch(err => {
      console.log(err);
      Notification.error('Un problème est survenu lors du chargement des cédules');
    });
  };

  $scope.selectSchedule = function(subjobSchedule, index, filteredObjects){
    const scheduleIdx = $scope.schedules.findIndex( x =>x.subjob.title === filteredObjects[index].job)
    $modal.open({
      animation: true,
      templateUrl: '../../components/modal/subjob-schedule/subjob-schedule-edit.html',
      controller: 'SubjobScheduleEdit',
      resolve:{
        subjobSchedule: function () {
          return $scope.schedules[scheduleIdx];
        },
        editable: function () {
          return false;
        }
      }
    });
  };

  $scope.init();

});
